@import 'src/assets/styles/variables';

.divider {
  &.MuiDivider-root {
    width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
}

.navButton {
  &.MuiListItemButton-root {
    background: none !important;
    text-transform: uppercase;
    padding-left: $size-lg;
  }
}

.navButtonIcon {
  min-width: unset !important;
  padding: 8px;
  border-radius: 4px;
}

.navButtonText {
  > span {
    color: $grey;
    font-weight: $font-weight-bold;
    margin-left: 12px;
  }
}
