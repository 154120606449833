@import 'variables';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $white2 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-default {
  &.MuiButton-root {
    background: $mainColor;
    color: $white1;
    font-size: 14px;
    font-weight: $font-weight-bold;
    padding-left: $size-lg;
    padding-right: $size-lg;

    &:hover {
      background: $mainColor;
    }
  }
}

.button-close {
  &.MuiButton-root {
    border-color: $mainColor;
    color: $mainColor;
    font-size: 14px;
    font-weight: $font-weight-bold;
    padding-left: $size-lg;
    padding-right: $size-lg;

    &:hover {
      border-color: $mainColor;
      background: transparent;
    }
  }
}
